import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { CurriculumComponent } from "./curriculum.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [CurriculumComponent],
  imports: [BrowserModule, CommonModule, NgbModule],
  exports: [CurriculumComponent],
  providers: [],
})
export class CurriculumModule {}
