<div class="section">
  <div class="container">
    <div class="row max-mb-n50">
      <div class="col-lg-9 col-12 order-lg-1 max-mb-50">
        <div class="course-details-wrapper">
          <div class="course-nav-tab">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav">
              <li [ngbNavItem]="1">
                <a ngbNavLink>نظرة عامة</a>
                <ng-template ngbNavContent>
                  <div class="tab-content">
                    <div id="overview" class="tab-pane fade active show">
                      <div class="course-overview">
                        <h3 class="title">وصف الدورة التدريبية</h3>

                        <p>
                          علم طبي يشمل القوانين الإسلامية في القرآن و السنة ثم
                          يبني منهج وقائي وعلاجي لصحة حياة الإنسان حسب أحواله
                          وصحته و عمره وزمانه و مكانه في المأكل و المشرب وتعديل
                          توازناته.
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink>منهج تعليمي</a>
                <ng-template ngbNavContent>
                  <div class="tab-content">
                    <div id="curriculum" class="tab-pane fade active show">
                      <div class="course-curriculum">
                        <ul
                          *ngIf="getChapters(course?.chapters)?.length > 0; else emptySections"
                          class="curriculum-sections"
                        >
                          <li
                            class="single-curriculum-section"
                            *ngFor="let chapter of getChapters(course?.chapters)"
                          >
                            <div class="section-header">
                              <div
                                class="section-left"
                                (click)="navigateSection(chapter)"
                              >
                                <h5 class="title" *ngIf="chapter.title">
                                  {{ chapter.title }}
                                </h5>
                                <p
                                  class="section-desc"
                                  *ngIf="chapter.description"
                                >
                                  {{ chapter.description }}
                                </p>
                              </div>
                            </div>
                            <ul
                              class="section-content"
                              *ngIf="
                                chapter?.lessons?.length > 0;
                                else emptyLessons
                              "
                            >
                              <li
                                class="course-item"
                                *ngFor="
                                  let lesson of getLessons(chapter?.lessons)
                                "
                                (click)="navigateLesson(lesson)"
                              >
                                <a class="section-item-link lesson">
                                  <span class="item-name">
                                    {{ lesson.title }}
                                  </span>
                                  <div class="course-item-meta">
                                    <span
                                      class="item-meta count-questions"
                                      *ngIf="lesson?.hasQuiz"
                                    >
                                      {{ getQuestionsCount(lesson?.questions) }}
                                      questions
                                    </span>
                                    <span
                                      *ngIf="lesson?.duration"
                                      class="item-meta duration"
                                    >
                                      {{ lesson?.duration }}
                                    </span>
                                    <span
                                      class="item-meta item-meta-icon video"
                                    >
                                      <i class="fa-solid fa-video"></i>
                                    </span>
                                    <span
                                      *ngIf="isLocked(lesson)"
                                      class="item-meta item-meta-icon"
                                    >
                                      <i class="fa-solid fa-lock"></i>
                                    </span>
                                    <span
                                      *ngIf="isUnLocked(lesson)"
                                      class="item-meta item-meta-icon"
                                    >
                                      <i class="fa-solid fa-lock-open"></i>
                                    </span>
                                  </div>
                                </a>
                              </li>
                            </ul>
                            <ng-template #emptyLessons>
                              <div
                                class="learn-press-message success ml-15 mr-15"
                              >
                                <i class="fa"></i> لا توجد عناصر في هذا القسم
                              </div>
                            </ng-template>
                          </li>
                        </ul>
                        <ng-template #emptySections>
                          <div class="learn-press-message success ml-15 mr-15">
                            <i class="fa"></i> لا توجد عناصر في هذا القسم
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="3">
                <a ngbNavLink>التعليقات</a>
                <ng-template ngbNavContent>
                  <div class="tab-content">
                    <div id="reviews" class="tab-pane fade active show">
                      <div class="course-reviews">
                        <div class="course-rating">
                          <h3 class="title">Reviews</h3>
                          <div class="course-rating-content">
                            <div class="average-rating">
                              <p class="rating-title secondary-color">
                                متوسط التقييم
                              </p>
                              <div class="rating-box">
                                <div class="average-value primary-color">
                                  0
                                </div>
                                <div class="review-star">
                                  <div class="tm-star-rating">
                                    <span class="far fa-star"></span>
                                    <span class="far fa-star"></span>
                                    <span class="far fa-star"></span>
                                    <span class="far fa-star"></span>
                                    <span class="far fa-star"></span>
                                  </div>
                                </div>
                                <div class="review-amount">(0 تقييمات)</div>
                              </div>
                            </div>
                            <div class="detailed-rating">
                              <p class="rating-title secondary-color">
                                التقييم التفصيلي
                              </p>
                              <div class="rating-box">
                                <div class="rating-rated-item">
                                  <div class="rating-point">
                                    <div class="tm-star-rating">
                                      <span class="fas fa-star"></span>
                                      <span class="fas fa-star"></span>
                                      <span class="fas fa-star"></span>
                                      <span class="fas fa-star"></span>
                                      <span class="fas fa-star"></span>
                                    </div>
                                  </div>
                                  <div class="rating-progress">
                                    <div class="single-progress-bar">
                                      <div class="progress">
                                        <div
                                          class="progress-bar"
                                          role="progressbar"
                                          style="width: 0%"
                                          aria-valuenow="0"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="rating-count">0</div>
                                </div>

                                <div class="rating-rated-item">
                                  <div class="rating-point">
                                    <div class="tm-star-rating">
                                      <span class="fas fa-star"></span>
                                      <span class="fas fa-star"></span>
                                      <span class="fas fa-star"></span>
                                      <span class="fas fa-star"></span>
                                      <span class="far fa-star"></span>
                                    </div>
                                  </div>
                                  <div class="rating-progress">
                                    <div class="single-progress-bar">
                                      <div class="progress">
                                        <div
                                          class="progress-bar"
                                          role="progressbar"
                                          style="width: 0%"
                                          aria-valuenow="0"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="rating-count">0</div>
                                </div>

                                <div class="rating-rated-item">
                                  <div class="rating-point">
                                    <div class="tm-star-rating">
                                      <span class="fas fa-star"></span>
                                      <span class="fas fa-star"></span>
                                      <span class="fas fa-star"></span>
                                      <span class="far fa-star"></span>
                                      <span class="far fa-star"></span>
                                    </div>
                                  </div>
                                  <div class="rating-progress">
                                    <div class="single-progress-bar">
                                      <div class="progress">
                                        <div
                                          class="progress-bar"
                                          role="progressbar"
                                          style="width: 0%"
                                          aria-valuenow="0"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="rating-count">0</div>
                                </div>

                                <div class="rating-rated-item">
                                  <div class="rating-point">
                                    <div class="tm-star-rating">
                                      <span class="fas fa-star"></span>
                                      <span class="fas fa-star"></span>
                                      <span class="far fa-star"></span>
                                      <span class="far fa-star"></span>
                                      <span class="far fa-star"></span>
                                    </div>
                                  </div>
                                  <div class="rating-progress">
                                    <div class="single-progress-bar">
                                      <div class="progress">
                                        <div
                                          class="progress-bar"
                                          role="progressbar"
                                          style="width: 0%"
                                          aria-valuenow="0"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="rating-count">0</div>
                                </div>

                                <div class="rating-rated-item">
                                  <div class="rating-point">
                                    <div class="tm-star-rating">
                                      <span class="fas fa-star"></span>
                                      <span class="far fa-star"></span>
                                      <span class="far fa-star"></span>
                                      <span class="far fa-star"></span>
                                      <span class="far fa-star"></span>
                                    </div>
                                  </div>
                                  <div class="rating-progress">
                                    <div class="single-progress-bar">
                                      <div class="progress">
                                        <div
                                          class="progress-bar"
                                          role="progressbar"
                                          style="width: 0%"
                                          aria-valuenow="0"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="rating-count">0</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="course-reviews-area">
                          <ul class="course-reviews-list">
                            <!-- should be uncommented after adding review api -->
                            <!-- <li class="review">
                              <div class="review-container">
                                <div class="review-author">
                                  <img
                                    src="assets/images/review-author/author1.jpeg"
                                    alt="author"
                                  />
                                </div>
                                <div class="review-content">
                                  <h4 class="title">ednawatson</h4>
                                  <div
                                    class="review-stars-rated"
                                    title="5 out of 5 stars"
                                  >
                                    <div class="review-stars empty"></div>
                                  </div>
                                  <h5 class="review-title">
                                    Cover all my needs
                                  </h5>
                                  <div class="review-text">
                                    The course identify things we want to change
                                    and then figure out the things that need to
                                    be done to create the desired outcome. The
                                    course helped me in clearly define problems
                                    and generate a wider variety of quality
                                    solutions. Support more structures analysis
                                    of options leading to better decisions.
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li class="review">
                              <div class="review-container">
                                <div class="review-author">
                                  <img
                                    src="assets/images/review-author/author2.jpeg"
                                    alt="author"
                                  />
                                </div>
                                <div class="review-content">
                                  <h4 class="title">Owen Christ</h4>
                                  <div
                                    class="review-stars-rated"
                                    title="5 out of 5 stars"
                                  >
                                    <div class="review-stars empty"></div>
                                  </div>
                                  <h5 class="review-title">
                                    Engaging &amp; Fun
                                  </h5>
                                  <div class="review-text">
                                    This is the third course I attend from you,
                                    and I absolutely loved all of them.
                                    Especially this one on leadership. Your
                                    method of explaining the concepts, fun,
                                    engaging and with real-world examples, is
                                    excellent. This course will help me a lot in
                                    my job, my career, and life in general, and
                                    I thank you for that. Thank you for
                                    improving the lives of many people with
                                    engaging and in-depth courses.
                                  </div>
                                </div>
                              </div>
                            </li> -->
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>

            <div [ngbNavOutlet]="nav"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
