import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { LifeStyleComponent } from "./life-style.component";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [LifeStyleComponent],
  imports: [BrowserModule, CommonModule, RouterModule],
  exports: [LifeStyleComponent],
  providers: [],
})
export class LifeStyleModule {}
