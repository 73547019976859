import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { LessonComponent } from './lesson/lesson.component';
import { LessonsComponent } from './lessons/lessons.component';
import { QcmComponent } from './qcm/qcm.component';
import { UnitComponent } from './unit/unit.component';
import { UnitsComponent } from './units/units.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'home' }
  },
  {
    path: 'admin',
    component: AdminComponent,
    data: { title: 'admin' }
  },
  {
    path: 'lesson',
    component: LessonComponent,
    data: { title: 'lesson' }
  },
  {
    path: 'lessons',
    component: LessonsComponent,
    data: { title: 'lessons' }
  },
  {
    path: 'unit',
    component: UnitComponent,
    data: { title: 'unit' }
  },
  {
    path: 'units',
    component: UnitsComponent,
    data: { title: 'unit' }
  },
  {
    path: 'qcm',
    component: QcmComponent,
    data: { title: 'qcm' }
  },
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
