import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../globals';
import { User, UserService } from '../user.service';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.css']
})
export class UnitsComponent implements OnInit {
  @Input() authData = { email: '', password: ''}
  @Input() userData = { name: '', email: '', password: ''}
  displaymod: boolean;
  displaymod1: boolean;
  hamburger: boolean=false
  globals: Globals;
  loginMsg: string
  signinMsg:string
  isAdmin: boolean
  user: User
  connected: boolean;

  

  constructor(private route: ActivatedRoute, private router: Router, globals: Globals, public userService: UserService) {
    this.globals = globals;
   }
  @HostListener('document:click', ['$event'])
  clickout() {
    if (this.displaymod) {
      this.hide()
    }
    if (this.displaymod1) {
      this.hide1()
    }
  }

  ngOnInit(): void {
    this.getCurrent()
  }
  show(): void {
    this.displaymod=true
    this.hamburger=false
  }
  hide(): void {
    this.displaymod=false;
    this.userData = { name: '', email: '', password: ''}
    this.signinMsg = undefined
  }
  show1(): void {
    this.displaymod1=true
    this.hamburger=false

  }
  hide1(): void {
    this.displaymod1=false;
    this.authData =  { email: '', password: ''}
    this.loginMsg = undefined
    console.log(this.loginMsg)
  }
  alternate(): void{
    this.displaymod=!this.displaymod
    this.displaymod1=!this.displaymod1
  }
  toHome(): void{
    this.hamburger=false
    this.router.navigate(['/home' , {section: "principal"}])
  }
  toManaging(): void{
    this.hamburger=false
    this.router.navigate(['/home' , {section: "board"}])

  }
  display(id: number): void{
    this.hamburger=false
    this.router.navigate(['/unit' , {unitid: id}])

    
  }
  toEducation(): void{
    this.hamburger=false
    window.location.reload();
  }
  openH(): void {
    this.hamburger= !this.hamburger
    console.log(this.hamburger)
  }
  getCurrent(): void {
    this.userService.getCurrent().subscribe((resp) => {
      console.log(resp)
      if(resp.id!== undefined){
        this.user=resp
        this.connected=true
        if(this.user.role==="admin"){
          this.router.navigate(['/home' , {section: "principal"}])
        }
      }
    })
  }
  authUser(): void {
    console.log(this.authData)
    this.userService.authUser(this.authData).subscribe((resp) => {
      console.log(resp.msg)
      if(resp.msg!== undefined){
        if(resp.msg==="account not active"){
          this.loginMsg="not active"
        }
        if(resp.msg==="wrong password or email"){
          this.loginMsg="false"
        }

      }else{
        this.loginMsg="good"
        this.connected=true
        // console.log(this.loginMsg)
        this.user=resp
        if(this.user.role==="admin"){
          this.router.navigate(['/home' , {section: "principal"}])
        }
        // console.log(this.user)
        this.hide1()
      }
    })
  }
  logout(): void {
    this.userService.logout().subscribe((resp) => {
      console.log(resp)
      if(resp==="session reseted"){
        this.connected=false
        this.isAdmin=false
        this.hamburger=false
      }
    })
  }
  addNewUser(): void {
    this.userService.addUser(this.userData).subscribe((resp) => {
      console.log(resp)
      if(resp.msg!== undefined){
        if(resp.msg==="please fill all required information"){
          this.signinMsg="missing"
        }
        if(resp.msg==="user with that email exists already"){
          this.signinMsg="exist"
        }

      }else{
        this.signinMsg="good"
        console.log("next")
      }
    })
  }

}
