import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-life-style",
  templateUrl: "./life-style.component.html",
  styleUrls: ["./life-style.component.css"],
})
export class LifeStyleComponent {
  @Output() join: EventEmitter<void> = new EventEmitter();

  constructor() {}

  getYear() {
    return new Date().getFullYear();
  }

  joinEvent() {
    this.join.emit();
  }
}
