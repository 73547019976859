import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "courses-list",
  templateUrl: "./courses.component.html",
  styleUrls: ["./courses.component.css"],
})
export class CoursesComponent implements OnInit {
  @Input() courses = [
    {
      id: 1,
      order: 1,
      title: "الوحدة 1: التغذية العلاجية في الطب الإسلامي",
      description: "Nutrition & Diétothérapie : selon la doctrine musulman",
    },
    {
      id: 2,
      order: 2,
      title: "الوحدة 2: الجهاز الهضمي و علاج أمراضه في الطب الإسلامي",
      description: "Tube digestif & réflexions selon la doctrine musulman",
    },
    {
      id: 3,
      order: 3,
      title: "الوحدة 3: الجهازالتنفسي و علاج أمراضه في الطب الإسلامي",
      description: "Respiration & réflexions selon la doctrine musulman",
      lessons: [],
    },
    {
      id: 4,
      order: 4,
      title: "الوحدة 4: الجهاز العصبي و علاج أمراضه في الطب الإسلامي",
      description:
        "Système neuro-végétif & réflexions selon la doctrine musulman",
    },
    {
      id: 5,
      order: 5,
      title: "الوحدة 5: الجهاز الغدد الصماء و علاج أمراضها في الطب الإسلامي",
      description:
        "Système endocrinien & réflexions selon la doctrine musulman",
    },
    {
      id: 6,
      order: 6,
      title: "الوحدة 6: الجهاز البولي و علاج أمراضه في الطب الإسلامي",
      description: "Système urinaire & réflexions selon la doctrine musulman",
    },
    {
      id: 7,
      order: 7,
      title: "الوحدة 7: الجلد و الشعر و علاج أمراضه في الطب الإسلامي",
      description: "Peau, cheveux & réflexions selon la doctrine musulman",
    },
  ];

  @Output() navigate: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  getCourses(courses) {
    return this.sort(courses);
  }

  navigateToCourse(course) {
    this.navigate.emit(course);
  }

  sort(data) {
    return data.sort((a, b) => a.order - b.order);
  }
}
