<!DOCTYPE html>
<html dir="rtl" lang="ar">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <style>
        html {
            /*overflow: auto;*/
            position:fixed;
            padding:0;
            margin:0;

            top:0;
            left:0;

            width: 100%;
            height: 100%;
            font-size: 16px;
            line-height: 21px;
            overflow-y: auto;
        }
        nav{
            display: inline;
            width: 100%;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        header{
            padding:0;
            margin:0;
            background-color: white;
            border-bottom: 1px solid #d6d6d5;
            width: 100%;
            z-index: 1000;
            max-height: 7.5vh;
            position: fixed;
            min-height: 60px;
            
        }
        .heads{
            width: 95%;
            margin: auto;
        }
        .head{
           
           float: left;
           padding-bottom: 0;
           margin-bottom: 0;
           

       }
       .head li{
            float: left;
            display: inline;   
            height: 60px;
            line-height: 60px;
            padding-bottom: 0;
        }
        .head li a {
            text-decoration: none;
            color:  #3f3a64;
            font-weight: 500;
            white-space: nowrap;
            transition: 0.2s color ease;
            cursor: pointer;
            padding-bottom: 0;
        }
        .head li a:hover{
            color:  #20ad96;
        }
        .gras{
            font-weight: 700 !important;
        }

        .btn-primary-two {
            border-color: #3a7e69;
            background-color: #3a7e69;
            color: #fff;
        }

        .btn-hover-secondary:hover, .btn-hover-secondary:not(:disabled):not(.disabled).active{
            border-color: transparent;
            background-color: #3f3a64;
            color: #fff;
        }

        .btn-primary-two:focus, .btn-primary-two:hover {
            border-color: transparent;
            background-color: #2e6453;
            color: #fff;
        }

        .active{
            color: crimson !important;
            text-decoration: underline !important;
        }
        .name{
            display: inline;
            color:  #20ad96;
            font-weight: 700;
            height: 60px;
            line-height: 60px;
            font-size: 18px;
            margin-right: 10px;
        }
        .home{
            width: 95%;
            margin: auto;
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .wrapper{
            width: 95%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        
        }
        .grid{
            height: 90%;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 10px;
            /* margin-top: 12%; */
            justify-content: center;
            align-items: center;
            text-align: center;

        }
        .one{
            grid-column: 1;
            display: flex;
            min-height: 500px;
            
            justify-content: center;
            align-items: center;
            
        }
        .subone{
            width: 80%;
            text-align: start;
            height: 100%;
            
        }
        .subone h1{
            font-size: 4rem;
            line-height: 4.5rem;
            color: #3f3a64;
            font-family: "Playfair Display", serif;
        }
        .subone p {
            font-size: 1.5rem !important;
            line-height: 2rem !important;
            color: #3a7e69;
            font-style: italic;
            font-weight: 400;
            text-align: justify;
        }
        .subone button{
            font-size: 1.5rem !important;
            line-height: 2rem !important;
        }
        .two{
            grid-column: 2;
        }
        .modal {
            display: none; /* Hidden by default */
            position: fixed; /* Stay in place */
            z-index: 10000; /* Sit on top */
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: hidden; /* Enable scroll if needed */
            background-color: rgb(0,0,0); /* Fallback color */
            background-color: rgba(0, 0, 0, 0.83); /* Black w/ opacity */
        
        }
        .modal-content {
            background-color: #fefefe;
            margin: 4.5% auto; /* 15% from the top and centered */
            padding:0 20px ;
            border: 1px solid #888;
            width: 35%;
            height: 85%; /* Could be more or less, depending on screen size */
            overflow-y: auto;
            border-radius: 10px;
            
        }
        .modal-content1 {
            background-color: #fefefe;
            margin: 2% auto; /* 15% from the top and centered */
            padding:0 20px ;
            border: 1px solid #888;
            width: 60%;
            height: 95%; /* Could be more or less, depending on screen size */
            overflow-y: auto;
            border-radius: 10px;
            
        }
        .img{
            width: 250px;
            height: 350px;
        }
        .desc{
            text-align: start;
        }
        .h2{
            font-weight: 200;
        }
        
        .open{
            display: block;
            
        }
        .hide{
            display: none;
        }
        .close {
    
            color: black;
            float: right;
            font-size: 28px;
            font-weight: bold;
            margin-left: 99%;
            margin-right: -1%;
            top: 3%;
        }
        .close:hover,
        .close:focus {
            color: rgb(122, 4, 4);
            text-decoration: none;
            cursor: pointer;
        }
        .container{
            width: 85%;
            text-align: center;
        }
        .modal-content form{
            text-align: start;
        }
        .modal-content input{
            height: 50px;
        }
        .modal-content label{
            font-size: 1.2rem;
            font-weight: 500;
        }
        .modal-content button{
            font-size: 1.5rem;
            font-weight: 700;
        }
        .modal-content a{
            color: #0d6efd;
            text-decoration: underline;
        }
        .Managing{
            min-height: 100vh;
            display: flex; 
            
            justify-content: center;
        }
        .education{
            height: 100vh;
            display: flex; 
            align-items: center;
            justify-content: center;
        }
        .content{
            width: 100%;
            margin-top: 3vh;
           
        }
        .content h1{
            /* font-size: 1.8rem; */
            text-align: center;
            margin: auto;
        }
        .content1{
            width: 100%;
            margin-top: 9vh;
           
        }
        .content1 h1{
            /* font-size: 1.8rem; */
            text-align: center;
            margin: auto;
        }
        .unit h1{
            margin-top: 3px;
            margin-bottom: 4px;
            font-size: 2rem;
        }
        .unit p{
            text-align: center;
            font-size: 1.1rem;
        }
        .units{
            height: 75vh;
            width: 95%;
            margin: auto;
            margin-top: 3vh;
            margin-bottom: 10px;
        }
        .unit{
            height: auto;
            /* max-height: 10vh; */
            /* background-color: crimson; */
            width: 100%;
            border: 1px solid gray;
            border-bottom-right-radius:  20px;
            border-top-left-radius: 20px;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;
            margin-bottom: 1vh;
        }
        .unit:hover {
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
            background-color:  #20ad96ab;
            cursor: pointer;
        }
        .cards{
            height: auto;
            width: 85%;
            margin: auto;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            align-items: center;
            overflow-y: auto;
        }
        .card {
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;
            border-radius: 5px;
            /* margin-left: 60px; */
            width: 262px;
            margin-bottom: 10px;
            height: 400px;
        }
        .card img{
            border-radius: 5px 5px 0 0;
        }
        .card:hover {
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        }
        .container1 {
            padding: 2px 16px;
            border-top: 1px solid rgba(128, 128, 128, 0.342);
            width: 100%;
        }
        .hamburger {
            display: none;
            position: absolute;
            top: 15px;
            left: 2.5%;
        }
        .bar {
            display: block;
            width: 25px;
            height: 3px;
            margin: 5px auto;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            background-color:  #20ad96ab;
        }
        .error{
            color: crimson;
            font-weight: 500;
        }
        .er{
            font-size: 1.2rem;
        }
        @media only screen and (max-width:430px){
            .name{
                font-size: 1rem !important;
                margin-right: 3px;
            }
        }
        @media only screen and (max-width:1281px){
            .head{
                position: fixed;
                right: -100%;
                display: flex;
                flex-direction: column-reverse;
                background-color: #fff;
                width: 100%;
                border-radius: 10px;
                text-align: center;
                transition: 0.3s;
                box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
                align-items: center;
                text-align: center;
            }
            .head li{
                margin-left: 0rem !important;
                text-align: center;

            }
            .head li button{
                margin: auto;
            }
            .head ul{
                padding-inline-start: 0px;
            }
            .head.active1{
                right: 0;
            }
            .hamburger {
                display: block;
                cursor: pointer;
            }
            .hamburger.active1 .bar:nth-child(2) {
                opacity: 0;
            }
            .hamburger.active1 .bar:nth-child(1) {
                transform: translateY(8px) rotate(45deg);
            }

            .hamburger.active1 .bar:nth-child(3) {
                transform: translateY(-8px) rotate(-45deg);
            }

        }
         @media only screen and (max-width:768px){
            .grid{
                
                grid-template-rows: repeat(2, 1fr);
            }
            .one{
                grid-column: 1/3;
                grid-row: 2;
                column-gap: 0;

            }
            .subone{
                text-align: center;
            }
            .two{
                margin-top: 60px;
                grid-column: 1/3;
                grid-row: 1;
                column-gap: 0px;
                text-align: center;


            }
            .two img{
                width: 100%;
            }
            .modal-content{
                width: 98%;
                height: auto;
            }
            .modal-content1{
                width: 98%;
            }
        } 
        .par{
            font-size: 1.2rem;
        }
        .list{
            text-align: start;
        }
        .list li{
            margin-bottom: 10px;
        }
    </style>
</head>
<body>
    <header>
        <div class="nav">
            <nav class="heads">
                <img src="../../assets/p1-r1.png" alt="ssss" height="50px" >
                <p class="name">مركزالطب الإسلامي للدراسات العلاجية و التطبيقية</p>
                <ul class="head" [ngClass]="hamburger  ? 'active1': ''">
                    <li style="margin-left: 0;" *ngIf="!this.connected"><button class="btn btn-primary btn-hover-secondary gras" (click)="this.show()" (click)="$event.stopPropagation()">إشترك معنا</button></li>
                    <li style="margin-left: 0;" *ngIf="this.connected"><button class="btn btn-primary btn-hover-secondary gras" (click)="this.logout()" (click)="$event.stopPropagation()">تسجيل خروج</button></li>
                    <li style="margin-left: 2rem;" *ngIf="!this.connected"><a class="gras" (click)="this.show1()" (click)="$event.stopPropagation()">تسجيل الدخول</a></li>
                    <li style="margin-left: 2rem;" *ngIf="this.connected"><a class="gras" (click)="this.show1()" (click)="$event.stopPropagation()"> مرحبا، {{ user.name }}</a></li>
                    <li style="margin-left: 2rem;"><a class="gras" >إتصل بنا</a></li>
                    <li style="margin-left: 2rem;"><a class="gras" >الأحداث</a></li>
                    <li style="margin-left: 2rem;"><a class="gras" >الرزنامة</a></li>
                    <li style="margin-left: 2rem;" *ngIf="!isAdmin"><a class="gras" (click)="this.toEducation()">برنامج التعليم</a></li>
                    <li style="margin-left: 2rem;" *ngIf="isAdmin"><a class="gras" (click)="this.AM()">إدارة المستخدمين</a></li>
                    <li style="margin-left: 2rem;"><a class="gras" (click)="this.toManaging()">الهيئة المديرة</a></li>
                    <li style="margin-left: 2rem;"><a class="gras" (click)="this.toHome()" >الرئيسية</a></li>
                </ul>
                <div class="hamburger" (click)="openH()" [ngClass]="hamburger  ? 'active1': ''">
                    <span class="bar"></span>
                    <span class="bar"></span>
                    <span class="bar"></span>
                </div>
            </nav>
            
        </div>
    </header>
    <div class="home section" id="home">
        <div class="wrapper">
            <div class="grid">
                <div class="one">
                    <div class="subone">
                        <h1>ثقافتك في صحتك</h1>
                        <p>علم طبي يشمل القوانين الإسلامية في القرآن و السنة ثم يبني منهج وقائي وعلاجي لصحة حياة الإنسان حسب أحواله وصحته و عمره وزمانه و مكانه في المأكل و المشرب وتعديل توازناته.</p>
                        <button class="btn btn-primary btn-hover-secondary gras" *ngIf="!this.connected" (click)="show()" (click)="$event.stopPropagation()">إشترك معنا</button>
                        <button class="btn btn-primary btn-hover-secondary gras" *ngIf="this.connected" (click)="this.logout()"(click)="this.logout()" (click)="$event.stopPropagation()">تسجيل الخروج</button>
                    </div>
                </div>
                <div class="two">
                    <img src="../../assets/p1-r.png" alt="ssss" height="100%" >
                </div>
            </div>
        </div>
    </div>

    <app-life-style
        (join)="show()"
    >
    </app-life-style>

    <div class="Managing section" id="Managing Body">
        <div class="content1">
            <h1 style="color:  #20ad96;">أعضاء الهيئة المديرة</h1><br><br><br>
            <div class="cards">
                <div class="card" (click)="openM(1)" (click)="$event.stopPropagation()">
                    <img src="../../assets/personne.png" alt="Avatar" style="width:260px; height: 280px;">
                    <div class="container">
                      <h4><b>لطفي القنين</b></h4>
                      <p>دكتور في الصيدلة و رئيس مركز الطب الإسلامي للدراسات العلاجية و التطبيقية</p>
                    </div>
                </div>
                <div class="card" (click)="openM(2)" (click)="$event.stopPropagation()">
                    <img src="../../assets/personne1.png" alt="Avatar" style="width:260px; height: 280px;">
                    <div class="container">
                        <h4><b>ياسين مالك</b></h4>
                        <p>طبيب متفقد عام للصحة العمومية بالصندوق الوطني للتأمين على المرض</p>
                    </div>
                </div>
                <div class="card" (click)="openM(3)" (click)="$event.stopPropagation()">
                    <img src="../../assets/avatar.png" alt="Avatar" style="width:260px; height: 280px;">
                    <div class="container">
                        <h4><b>العضو</b></h4>
                        <p>طبيب متفقد عام للصحة العمومية بالصندوق الوطني للتأمين على المرض</p>
                    </div>
                </div>
                <div class="card" (click)="openM(4)" (click)="$event.stopPropagation()">
                    <img src="../../assets/avatar.png" alt="Avatar" style="width:260px; height: 280px;">
                    <div class="container">
                        <h4><b>العضو</b></h4>
                        <p>طبيب متفقد عام للصحة العمومية بالصندوق الوطني للتأمين على المرض</p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <!-- <div class="education" id="education program">
        <div class="content">
            <h1 style="color:  #20ad96; font-size: 2rem;">البرنامج التعليمي يتكون من ثمانية وحدات وكل وحدة لها من الحصص 20 حصة وكل حصة تدوم 60 دقيقة</h1>
            <div class="units">
                <div class="unit" (click)="display(1)" (click)="$event.stopPropagation()">
                    <h1>الوحدةـ1ـ : التغذية العلاجية في الطب الإسلامي</h1>
                    <p>(Nutrition & Diétothérapie : selon la doctrine musulman)</p>
                </div>
                <div class="unit"  (click)="display(2)" (click)="$event.stopPropagation()">
                    <h1>الوحدةـ2ـ : الجهاز الهضمي و علاج أمراضه في الطب الإسلامي</h1>
                    <p>(tube digestif & réflexions selon la doctrine musulman)</p>
                </div>
                <div class="unit"  (click)="display(3)" (click)="$event.stopPropagation()">
                    <h1>الوحدةـ3ـ : الجهازالتنفسي و علاج أمراضه في الطب الإسلامي</h1>
                    <p>(Respiration & réflexions selon la doctrine musulman)</p>
                </div>
                <div class="unit"  (click)="display(4)" (click)="$event.stopPropagation()">
                    <h1>الوحدةـ4ـ : الجهاز العصبي و علاج أمراضه في الطب الإسلامي</h1>
                    <p>(système neuro-végétif & réflexions selon la doctrine musulman)</p>
                </div>
                <div class="unit"  (click)="display(5)" (click)="$event.stopPropagation()">
                    <h1>الوحدةـ5ـ : الجهاز الغدد الصماء و علاج أمراضها في الطب الإسلامي</h1>
                    <p>(système endocrinien & réflexions selon la doctrine musulman)</p>
                </div>
                <div class="unit"  (click)="display(6)" (click)="$event.stopPropagation()">
                    <h1>الوحدةـ6ـ : الجهاز البولي و علاج أمراضه في الطب الإسلامي</h1>
                    <p>(système urinaire & réflexions selon la doctrine musulman)</p>
                </div>
                <div class="unit"  (click)="display(7)" (click)="$event.stopPropagation()">
                    <h1>الوحدةـ7ـ : الجلد و الشعر و علاج أمراضه في الطب الإسلامي</h1>
                    <p>(Peau, cheveux & réflexions selon la doctrine musulman)</p>
                </div>
            </div>
        </div>
        
    </div> -->
    
    <div id="myModal" class="modal" [ngClass]="displaymod  ? 'open': 'hide'"  >

        <!-- Modal content -->
        <div id="ss" class="modal-content" (click)="$event.stopPropagation()">
            <span class="close" (click)="hide()" >&times;</span><br>
            <div class="container" *ngIf="this.signinMsg!=='good'">
                <h1>إشترك الأن</h1><br><br>
                <form>
                    <div class="form-group">
                        <label for="completeName" class="mb-2" style="margin-left: 5px;">الاسم الكامل</label>
                        <input [(ngModel)]="userData.name" name="completeName" id="completeName" type="text" class="form-control" placeholder="أدخل الاسم الأول والأخير">
                    </div><br>
                    <div class="form-group">
                        <label for="email" class="mb-2" style="margin-left: 5px;">البريد الإلكتروني</label>
                        <input [(ngModel)]="userData.email"  name="email" id="email" type="email" class="form-control" placeholder="nom@email.com">
                    </div>
                    <br>
                    <div class="form-group">
                        <label for="phone" class="mb-2" style="margin-left: 5px;">الهاتف</label>
                       <input [(ngModel)]="userData.phone"  name="phone" id="phone" type="number" class="form-control" placeholder="ادخل رقم الهاتف"> 
                        <!--<input type="text" placeholder="ادخل رقم الهاتف" class="form-control" id="phone" name="phone" maxlength="12" [(ngModel)]="userData.phone"
                         phoneMask [ngClass]="{ 'is-invalid': phone.touched || form.submitted && phone.invalid }"#phone="ngModel" 
                         phoneMask  (input)="numbersOnlyValidator($event)" />-->
                    </div>
                   <!-- <div *ngIf="(phone.touched || form.submitted) &&
                phone.invalid" class="invalid-feedback">
                  <div *ngIf="phone.errors">
                    ادخل رقم هاتف فعال
                  </div>
                </div>-->
                    <br>
                    <div class="form-group">
                        <label for="password" class="mb-2" style="margin-left: 5px;">كلمة السر</label>
                        <input [(ngModel)]="userData.password"  name="password" id="password" type="password" class="form-control" placeholder="ادخل كلمة السر">
                    </div><br><br>
                    <button class="btn btn-success" style="width: 100%;" (click)="addNewUser()">إنشاء حساب</button>
                </form>
                <br>
                <p class="error er" *ngIf="this.signinMsg==='missing'">يرجى ملء جميع المعلومات المطلوبة</p>
                <p class="error er" *ngIf="this.signinMsg==='exist'">مستخدم هذا البريد الإلكتروني موجود بالفعل</p>
                <br>
                <p>لديك حساب؟<a (click)="this.alternate()" (click)="$event.stopPropagation()" style="margin-right: 5px;">سجل الدخول</a></p>
            </div>
            <div class="container" *ngIf="this.signinMsg==='good'">
                <h1>إتمام الإشتراك</h1><br><br>
                <p class="par">لإتمام الإشتراك و لتفعيل حسابك من قبل الهيئة المديرة، يرجى منك إرسال المستندات التالية:</p>
                <ul class="par list">
                    <li>صورة من بطاقة التعريف الوطنية</li>
                    <li>صورة هوية</li>
                    <li>صورة من الشهادة في المستوى العلمي</li>
                    <li> وصل شراء كتاب التغذية و علاج أمراضها من دار المالكية للطباعة و النشر و التوزيع أو الاتصال بالادارة عن طريق البريد الالكتروني لشراءه</li>
                </ul>
                <p class="par">البريد الإلكتروني التالي:</p>
                <h2 style="color:  #20ad96;">islammedicale@gmail.com</h2>
            </div>
           
        </div>
    </div>
    <div id="myModal" class="modal" [ngClass]="displaymod1  ? 'open': 'hide'"  >

        <!-- Modal content -->
        <div id="ss" class="modal-content" (click)="$event.stopPropagation()">
            <span class="close" (click)="hide1()" >&times;</span><br>
            <div class="container">
                <h1>مرحبا بعودتك</h1><br><br>
                <form>
                    <div class="form-group">
                        <label for="email" class="mb-2" style="margin-left: 5px;">البريد الإلكتروني</label>
                        <input [(ngModel)]="authData.email"  name="email" id="email" type="text" class="form-control" placeholder="nom@email.com">
                    </div>
                    <br>
                    <div class="form-group">
                        <label for="password" class="mb-2" style="margin-left: 5px;">كلمة السر</label>
                        <input [(ngModel)]="authData.password" name="password" id="password" type="password" class="form-control" placeholder="ادخل كلمة السر">
                    </div>
                    <a href="">نسيت كلمة السر؟</a><br><br>
                    <button class="btn btn-success" style="width: 100%;" (click)="authUser()" >سجل الدخول</button>
                </form>
                <br>
                <p class="error" *ngIf="this.loginMsg==='not active'">هذا الحساب غير مفعل <br> يرجى الإتصال هذا البريد الإلكتروني لمزيد من المعلومات</p>
                <p *ngIf="this.loginMsg==='not active'">islammedicale@gmail.com</p>
                <p class="error er" *ngIf="this.loginMsg==='false'">كلمة سر أو بريد إلكتروني خاطئ</p>
                <br>
                <p>ليس لديك حساب؟<a (click)="this.alternate()" (click)="$event.stopPropagation()"style="margin-right: 5px;">أنشأ حساب</a></p>
            </div>
           
        </div>
    </div>
    <div id="myModal" class="modal" [ngClass]="displaymod2  ? 'open': 'hide'"  >

        <!-- Modal content -->
        <div id="ss" class="modal-content1" (click)="$event.stopPropagation()">
            <span class="close" (click)="hide2()" >&times;</span><br>
            <div class="container" *ngIf="this.id===1">
                <img src="../../assets/personne.png" alt="sss" class="img">
                <h1>لطفي القنين</h1>
                <h4>دكتور في صيدلة</h4>
                <h4>رئيس مركز الطب الإسلامي للدراسات العلاجية</h4>
                <ul class="desc">
                    <li>ولد بمدينة رادس – تونس- سنة 1956</li>
                    <li>دكتور في الصيدلة متخرج من كلية الصيدلة ببوردو - فرنسا سنة 1983 </li>
                    <li>مديرا عاما بشركة توزيع الأدوية بالجملة من سنة 1983إلى سنة2001 </li>
                    <li>صاحب صيدلية بحي ابن سينه بتونس العاصمة منذ سنة 2001 </li>
                    <li>اختصاصي في العلاج ألسريري النباتي والعطري، ومن مؤسسي جمعية العلاج النباتي ألسريري بتونس STPC</li>
                    <li>اختصاصي في صناعة وعلاج مواد النباتية والمكونات الغذائية</li>
                    <li>اختصاصي في صناعة مواد التجميل والعطرات والمواد الصحية </li>
                    <li>رئيس اللجنة الصحية الزيتونية بتونس</li>
                </ul>
            </div>
            <div class="container" *ngIf="this.id===2">
                <img src="../../assets/personne1.png" alt="sss" class="img">
                <h1>ياسين مالك</h1>
                <h4>طبيب متفقد عام للصحة العمومية بالصندوق الوطني للتأمين على المرض</h4>
                <ul class="desc">
                    <li>شهادة ختم الدروس في "اللغة الأنقليزية" بمعهد بورقيبة للغات الحية : جوان 2017</li>
                    <li>شهادة الدراسات المكملة في "الإدمان":Certificat d’études complémentaires en "Addictologie" :<br> بكلية الطب بتونس: أوت 2015</li>
                    <li>شهادة الدراسات المكملة في "الأخلاقيات في الممارسات والأبحاث الطبية":<br> Certificat d’études complémentaires en "Ethique de la pratique et de la recherche médicale"  بكلية الطب بسوسة- تونس: ديسمبر 2014.</li>
                    <li>ماجستير مختصّ في "الإعلامية الطبيّة": Mastère Professionnel en "Informatique médicale" بكلية الطب بسوسة: أكتوبر 2013.</li>
                    <li>شهادة الدراسات المكملة في "الطرق الإحصائية و المعطيات الوبائية":  Certificat d’études complémentaires en "Méthodologie statistique et épidémiologie" بكلية الطب بتونس: جوان 2013.</li>
                    <li> شهادة الدراسات المكملة في "الوقاية والرعاية التلطيفية من الأمراض السرطانية":  Certificat d’études complémentaires en "Prévention et soins palliatifs en cancérologie"  بكلية الطب بسوسة: أوت 2013.</li>
                    <li>شهادة الدراسات المكملة في "إدارة المستشفيات والاقتصاد الصحي":  Certificat d’études complémentaires en "Gestion hospitalière et économie de la santé" بكلية الطب بتونس : جوان 2011.</li>
                    <li>ماجستير مختصّ في "الجبر القانوني للضرر البدني":  Mastère Spécialisé en "Réparation juridique du dommage corporel" بكلية الطب بتونس : أوت 2008.</li>
                    <li>متحصل علي شهادة الكفاءة في الجبر القانوني للضرر البدني من عمادة الأطباء بتونس بتاريخ  07/01/2009.</li>
                    <li>مسجّل بمرسوم من وزير العدل وحقوق الإنسان بتاريخ 30/06/2009 بقائمة الخبراء العدليين في الجبر القانوني للضرر البدني. </li>
                    <li>ماجستير مختصّ في "طب الشغل":  Mastère Spécialisé en "Médecine du travail": بكلية الطب بتونس: أكتوبر 2004</li>
                    <li>متحصل علي شهادة الكفاءة في الأمراض المهنية من عمادة الأطباء بتونس بتاريخ  10/11/2008.</li>
                    <li>دكتوراه الدولة في "الطبّ" بكلية الطب بسوسة: جويلية 1998.الملاحظة: مشرف جدّا مع شكر و تهنئة أعضاء اللّجنة</li>
                    <li>باكالوريا علوم ورياضيات: جوان 1988 بالقيروان. الملاحظة:  قريب من الحسن.</li>
                </ul>
            </div>
           
        </div>
    </div>

    <app-footer></app-footer>
</body>
</html>