import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../globals';
import { User, UserService } from '../user.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  @Input() authData = { email: '', password: ''}
  @Input() userData = { name: '', email: '', password: ''}
  @Input() stateData = { state: ''}
  displaymod: boolean;
  displaymod1: boolean;
  displayy: boolean;
  connected: boolean
  section: string
  hamburger: boolean=false
  id: number
  globals: Globals;
  loginMsg: string
  signinMsg:string
  isAdmin: boolean
  user: User
  users: User


  constructor(private route: ActivatedRoute, private router: Router, globals: Globals, public userService: UserService) {
    this.globals = globals;
   }
   @HostListener('document:click', ['$event'])
   clickout() {
     if (this.displaymod) {
       this.hide()
     }
     if (this.displaymod1) {
       this.hide1()
     }

   }

  ngOnInit(): void {
    this.getCurrent()
    this.getUsers()
  }

  show(): void {
    this.displaymod=true
    this.hamburger=false

  }
  hide(): void {
    this.displaymod=false;
    this.userData = { name: '', email: '', password: ''}
    this.signinMsg = undefined
  }
  show1(): void {
    this.displaymod1=true
    this.hamburger=false

  }
  hide1(): void {
    this.displaymod1=false;
    this.authData =  { email: '', password: ''}
    this.loginMsg = undefined
    console.log(this.loginMsg)
  }
  alternate(): void{
    this.displaymod=!this.displaymod
    this.displaymod1=!this.displaymod1
  }
  openH(): void {
    this.hamburger= !this.hamburger
    console.log(this.hamburger)
  }
  authUser(): void {
    console.log(this.authData)
    this.userService.authUser(this.authData).subscribe((resp) => {
      console.log(resp.msg)
      if(resp.msg!== undefined){
        if(resp.msg==="account not active"){
          this.loginMsg="not active"
        }
        if(resp.msg==="wrong password or email"){
          this.loginMsg="false"
        }

      }else{
        this.loginMsg="good"
        this.connected=true
        // console.log(this.loginMsg)
        this.user=resp
        if(this.user.role==="admin"){
          this.isAdmin=true
        }else{
          this.isAdmin=false
        }
        // console.log(this.user)
        this.hide1()
      }
    })
  }
  getCurrent(): void {
    this.userService.getCurrent().subscribe((resp) => {
      console.log(resp)
      if(resp.id!== undefined){
        this.user=resp
        this.connected=true
        if(this.user.role==="admin"){
          this.isAdmin=true
        }else{
          this.router.navigate(['/home' , {section: "principal"}])
        }
      }
      if(resp==="not logged in"){
        this.router.navigate(['/home' , {section: "principal"}])
      }
    })
  }
  logout(): void {
    this.userService.logout().subscribe((resp) => {
      console.log(resp)
      if(resp==="session reseted"){
        this.connected=false
        this.isAdmin=false
        this.hamburger=false
        this.router.navigate(['/home' , {section: "principal"}])

      }
    })
  }
  addNewUser(): void {
    this.userService.addUser(this.userData).subscribe((resp) => {
      console.log(resp)
      if(resp.msg!== undefined){
        if(resp.msg==="please fill all required information"){
          this.signinMsg="missing"
        }
        if(resp.msg==="user with that email exists already"){
          this.signinMsg="exist"
        }

      }else{
        this.signinMsg="good"
        console.log("next")
      }
    })
  }
  toHome(): void{
    this.hamburger=false
    this.router.navigate(['/home' , {section: "principal"}])
  }
  toManaging(): void{
    this.hamburger=false
    this.router.navigate(['/home' , {section: "board"}])

  }
  getUsers(): void{
    this.userService.getUsers().subscribe((resp) => {
      this.users=resp
      console.log(this.users)

    })
  }
  updateState(state: string, id: number): void {
    console.log(id)
    this.stateData.state=state
    this.userService.updateState(this.stateData, id).subscribe((resp) =>{
      console.log(resp)
      this.getUsers()
    })
  }
  deleteUser(id: number): void{
    this.userService.deleteUser(id).subscribe((resp) => {
      console.log(resp)
      this.getUsers()
    })
  }

}
