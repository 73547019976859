import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "curriculum-list",
  templateUrl: "./curriculum.component.html",
  styleUrls: ["./curriculum.component.css"],
})
export class CurriculumComponent implements OnInit {
  @Input() course = {
    id: 1,
    order: 1,
    title: "الوحدة 1: التغذية العلاجية في الطب الإسلامي",
    description: "Nutrition & Diétothérapie : selon la doctrine musulman",
    chapters: [
      {
        id: 1,
        order: 1,
        title: "الدرس 1 : (تعريف الطب نبوي+vidéo)",
        lessons: [
          {
            id: 1,
            order: 1,
            title: "الدرس 1 : (تعريف الطب نبوي+vidéo)",
            duration: "60 دقيقة",
            hasQuiz: false,
            isPreview: true,
          },
        ],
      },
      {
        id: 2,
        order: 2,
        title: "الدرس 2: (vidéo+qcm)",
        lessons: [
          {
            id: 1,
            order: 1,
            title: "الدرس 1 : (تعريف الطب نبوي+vidéo)",
            duration: "60 دقيقة",
            hasQuiz: false,
            isPreview: true,
          },
        ],
      },
      {
        id: 3,
        order: 3,
        title: "الدرس 3: (vidéo+qcm)",
        lessons: [
          {
            id: 1,
            order: 1,
            title: "الدرس 1 : (تعريف الطب نبوي+vidéo)",
            duration: "60 دقيقة",
            hasQuiz: false,
            isPreview: true,
          },
        ],
      },
      {
        id: 4,
        order: 4,
        title: "الدرس 4: (vidéo+qcm)",
        lessons: [
          {
            id: 1,
            order: 1,
            title: "الدرس 1 : (تعريف الطب نبوي+vidéo)",
            duration: "60 دقيقة",
            hasQuiz: false,
            isPreview: true,
          },
        ],
      },
      {
        id: 5,
        order: 5,
        title: "الدرس 5: (vidéo+qcm)",
        lessons: [
          {
            id: 1,
            order: 1,
            title: "الدرس 1 : (تعريف الطب نبوي+vidéo)",
            duration: "60 دقيقة",
            hasQuiz: false,
            isPreview: true,
          },
        ],
      },
      {
        id: 6,
        order: 6,
        title: "الدرس 6: (vidéo+qcm)",
        duration: "60 دقيقة",
        hasQuiz: false,
        questions: [],
      },
      {
        id: 7,
        order: 7,
        title: "الدرس 7: (vidéo)",
        lessons: [
          {
            id: 1,
            order: 1,
            title: "الدرس 1 : (تعريف الطب نبوي+vidéo)",
            duration: "60 دقيقة",
            hasQuiz: false,
            isPreview: true,
          },
        ],
      },
      {
        id: 8,
        order: 8,
        title: "الدرس 8: (vidéo+qcm)",
        lessons: [
          {
            id: 1,
            order: 1,
            title: "الدرس 1 : (تعريف الطب نبوي+vidéo)",
            duration: "60 دقيقة",
            hasQuiz: false,
            isPreview: true,
          },
        ],
      },
      {
        id: 9,
        order: 9,
        title: "الدرس 9: (vidéo+qcm)",
        lessons: [
          {
            id: 1,
            order: 1,
            title: "الدرس 1 : (تعريف الطب نبوي+vidéo)",
            duration: "60 دقيقة",
            hasQuiz: false,
            isPreview: true,
          },
        ],
      },
      {
        id: 10,
        order: 10,
        title: "الدرس 10: (vidéo+qcm)",
        lessons: [
          {
            id: 1,
            order: 1,
            title: "الدرس 1 : (تعريف الطب نبوي+vidéo)",
            duration: "60 دقيقة",
            hasQuiz: false,
            isPreview: true,
          },
        ],
      },
    ],
  };

  @Output() chapter: EventEmitter<any> = new EventEmitter<any>();

  @Output() lesson: EventEmitter<any> = new EventEmitter<any>();

  active = 1;

  constructor() {}

  ngOnInit(): void {}

  getChapters(chapters) {
    return this.sort(chapters);
  }

  getLessons(lessons) {
    return this.sort(lessons);
  }

  navigateChapter(chapter) {
    this.chapter.emit(chapter);
  }

  navigateLesson(lesson) {
    this.lesson.emit(lesson);
  }

  sort(data) {
    return data.sort((a, b) => a.order - b.order);
  }

  getQuestionsCount(questions) {
    return questions?.length || 0;
  }

  isLocked(lesson) {
    return !lesson?.isPreview;
  }

  isUnLocked(lesson) {
    return lesson?.isPreview;
  }
}
