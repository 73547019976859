<div class="section section-padding-bottom">
  <div class="container">
    <!-- Course Top Bar Start -->
    <div class="row justify-content-between align-items-center max-mb-20">
      <div class="col-sm-auto col-12 max-mb-10">
        <p class="result-count">لقد وجدنا <span>7</span> دورات متاحة لك</p>
      </div>
    </div>

    <div class="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
      <div
        class="col max-mb-30 aos-init aos-animate"
        data-aos="fade-up"
        *ngFor="let course of getCourses(courses)"
      >
        <div class="course">
          <div class="thumbnail">
            <span class="badge">مجاني</span>
            <a (click)="navigateToCourse(course)" class="image"
              ><img src="assets/images/courses/course.png" alt="Course Image"
            /></a>
          </div>
          <div class="info">
            <span class="price">
              <span>00.</span>
              0 دينار
            </span>
            <h3 class="title">
              <a (click)="navigateToCourse(course)">
                {{ course?.title }}
              </a>
            </h3>
            <ul class="meta">
              <li><i class="far fa-file-alt"></i>10 دروس</li>
              <li><i class="fa-regular fa-user"></i>0 طلبة</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row max-mt-50" *ngIf="false">
      <div class="col text-center">
        <a
          href="JavaScript:Void(0);"
          class="btn btn-outline-primary load-more-btn"
          >تحميل المزيد <span class="fa-solid fa-rotate-right me-3"></span
        ></a>
      </div>
    </div>
  </div>
</div>
