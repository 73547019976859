import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Globals } from './globals';

export interface User {
  id: number;
  name: string;
  email: string;
  phone: string;
  unit: number;
  lesson: number;
  lessonpart: number;
  role: string;
  state: string;
  try: number;
  created_at: Date;
  updated_at: Date;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  globals: Globals;

  constructor(private http: HttpClient, globals: Globals) {
    this.globals = globals;
   }

   private extractData(res: Response): any {
    const body = res;
    //console.log(body)
    return body || { };

  }


  authUser(auth: any): Observable<any> {
    return this.http.post(this.globals.endpoint + 'auth', auth, { withCredentials: true}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    )
  }
  addUser(user: any): Observable<any> {
    return this.http.post(this.globals.endpoint + 'users', user, { withCredentials: true}).pipe(
      map(this.extractData),
      catchError(this.handleError)

    )
  }
  getCurrent(): Observable<any> {
    return this.http.get(this.globals.endpoint + 'me', {withCredentials: true}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    )
  }
  getUsers(): Observable<any> {
    return this.http.get(this.globals.endpoint + 'users', {withCredentials: true}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    )
  }
  logout(): Observable<any> {
    return this.http.get(this.globals.endpoint + 'logout', {withCredentials: true}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    )
  }
  updateState(state: any, id:number): Observable<any> {
    return this.http.put(this.globals.endpoint + 'users/' + id, state, { withCredentials: true }).pipe(
      catchError(this.handleError)
    );
  }
  updateEvaluation(evalu: any, id:number): Observable<any> {
    return this.http.put(this.globals.endpoint + 'evaluation/' + id, evalu, { withCredentials: true }).pipe(
      catchError(this.handleError)
    );
  }
  deleteUser(id:number): Observable<any> {
    return this.http.delete(this.globals.endpoint + 'users/' + id, {withCredentials: true}).pipe(
      map(this.extractData),
      catchError(this.handleError)
    )
  }


  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
  // private handleError(error: HttpErrorResponse): any {
  //   if (error.error instanceof ErrorEvent) {
  //     console.error('An error occurred:', error.error.message);
  //   } else {
  //     console.error(
  //       `Backend returned code ${error.status}, ` +
  //       `body was: ${error.error.message}`);
  //   }
  //   return of(new HttpResponse({body: { er: error.error.message, status: error.status}}));
  // }
}
