import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbNav, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { UnitComponent } from './unit/unit.component';
import { UnitsComponent } from './units/units.component';
import { Globals } from './globals';
import { HttpClientModule } from '@angular/common/http';
import { AdminComponent } from './admin/admin.component';
import { LessonComponent } from './lesson/lesson.component';
import { QcmComponent } from './qcm/qcm.component';
import { LessonsComponent } from './lessons/lessons.component';
import { CurriculumModule } from './shared/components/curriculum/curriculum.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { LifeStyleModule } from './shared/components/life-style/life-style.module';
import { CoursesModule } from './shared/components/courses/courses.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UnitComponent,
    UnitsComponent,
    AdminComponent,
    LessonComponent,
    QcmComponent,
    LessonsComponent
  ],
  imports: [
    CurriculumModule,
    LifeStyleModule,
    CoursesModule,
    FooterModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
  ],
  providers: [ Globals ],
  bootstrap: [AppComponent]
})
export class AppModule { }
