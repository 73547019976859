<div class="footer-section section section-margin-top">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-md-5 col-12 max-mb-50">
        <div class="footer-widget">
          <h4 class="footer-widget-title">العنوان</h4>
          <div class="footer-widget-content">
            <div class="content">
              <p><a href="#">info@example.com </a></p>
            </div>
            <div class="footer-social-inline">
              <a href="#"><i class="fab fa-facebook-square"></i></a>
              <a href="#"><i class="fa-brands fa-x-twitter"></i></a>
              <a href="#"><i class="fab fa-instagram"></i></a>
              <a href="#"><i class="fab fa-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-7 col-12 max-mb-50">
        <div class="footer-widget">
          <h4 class="footer-widget-title">استكشف</h4>
          <div class="footer-widget-content">
            <ul class="column-2">
              <li><a href="#">الرئيسية</a></li>
              <li><a href="#">الهيئة المديرة</a></li>
              <li><a href="#">برنامج التعليم</a></li>
              <li><a href="#">الرزنامة</a></li>
              <li><a href="#">إتصل بنا</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-3 col-sm-5 col-12 max-mb-50">
        <div class="footer-widget">
          <h4 class="footer-widget-title">معلومات</h4>
          <div class="footer-widget-content">
            <ul>
              <li><a href="#">برنامج التعليم</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row max-mt-20">
      <div class="col">
        <p class="copyright">
          © {{ getYear() }} Islamic Medicale.
          <a routerLink="/">All Rights Reserved</a>
        </p>
      </div>
    </div>
  </div>
</div>
