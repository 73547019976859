<div class="lifestyle-section section section-padding-bottom-200">
  <div class="container">
    <!-- Section Title Start -->
    <div
      class="section-title text-center aos-init aos-animate"
      data-aos="fade-up"
    >
      <span class="sub-title">معًا يمكننا خلق</span>
      <h2 class="title playfair-font">
        عالم أفضل لعيش <span>نمط حياة أكثر صحة.</span>
      </h2>
    </div>
    <!-- Section Title End -->

    <div class="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
      <div class="col max-mb-30 aos-init aos-animate" data-aos="fade-up">
        <div class="health-icon-box text-center">
          <div class="icon">
            <img src="assets/images/health-coaching-box-image-01.png" alt="" />
          </div>
          <div class="content">
            <h3 class="title">الروتين الرياضي</h3>
            <div class="desc">
              <p>
                تؤكد الأبحاث أن الروتين البدني يمكن أن يحسن الصحة خلال جميع
                مراحل الحياة.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col max-mb-30 aos-init aos-animate" data-aos="fade-up">
        <div class="health-icon-box text-center">
          <div class="icon">
            <img src="assets/images/health-coaching-box-image-02.png" alt="" />
          </div>
          <div class="content">
            <h3 class="title">إستراتيجيات التغذية</h3>
            <div class="desc">
              <p>
                الطعام الجيد هو متع الحياة العظيمة. وبالنسبة لمعظم الناس، فإن
                الوجبات الجيدة هي قلب الحياة العائلية والمناسبات
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col max-mb-30 aos-init aos-animate" data-aos="fade-up">
        <div class="health-icon-box text-center">
          <div class="icon">
            <img
              class="br-15"
              src="assets/images/health-coaching-box-image-03.jpeg"
              alt=""
            />
          </div>
          <div class="content">
            <h3 class="title">الدعم والتحفيز</h3>
            <div class="desc">
              <p>
                إن العيش بأسلوب حياة صحي هو عنصر أساسي في وسائل العافية يعتني بك
                من الداخل إلى الخارج.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row max-mt-50">
      <div class="col text-center">
        <a
          routerLink="/units"
          class="btn btn-off-white btn-hover-secondary margin-15"
          >أعرف أكثر
        </a>
        <a
          (click)="joinEvent()"
          class="btn btn-primary-two btn-hover-secondary margin-15"
        >
          إشترك معنا
        </a>
      </div>
    </div>
  </div>
</div>
