import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../globals';
import { User, UserService } from '../user.service';

@Component({
  selector: 'app-lesson',
  templateUrl: './lesson.component.html',
  styleUrls: ['./lesson.component.css']
})
export class LessonComponent implements OnInit {
  globals: Globals;
  trustedDashboardUrl : SafeResourceUrl;
  id: any
  lessonpart: any
  @Input() authData = { email: '', password: ''}
  @Input() userData = { name: '', email: '', password: ''}
  displaymod: boolean;
  displaymod1: boolean;
  displaymod2: boolean
  displayy: boolean;
  connected: boolean
  section: string
  hamburger: boolean=false
  loginMsg: string
  signinMsg:string
  isAdmin: boolean
  user: User


  constructor(private route: ActivatedRoute, private router: Router, globals: Globals, public userService: UserService, private sanitizer: DomSanitizer) {
    this.globals = globals;
    this.sanitizer = sanitizer;
   }
   @HostListener('document:click', ['$event'])
  clickout() {
    if (this.displaymod) {
      this.hide()
    }
    if (this.displaymod1) {
      this.hide1()
    }
    if (this.displaymod2) {
      this.hide2()
    }
  }

  ngOnInit(): void {
    this.id=this.route.snapshot.paramMap.get('lessonId')
    this.lessonpart=this.route.snapshot.paramMap.get('lessonpart')
    this.getCurrent()
    console.log(this.id)
    console.log(this.lessonpart)
  }
  uRL() {
    this.trustedDashboardUrl= this.sanitizer.bypassSecurityTrustResourceUrl(this.globals.endpoint+"vid"+this.id+".mp4")
    console.log(this.trustedDashboardUrl)
    return this.trustedDashboardUrl
  }
  show(): void {
    this.displaymod=true
    this.hamburger=false

  }
  hide(): void {
    this.displaymod=false;
    this.userData = { name: '', email: '', password: ''}
    this.signinMsg = undefined
  }
  show1(): void {
    this.displaymod1=true
    this.hamburger=false

  }
  hide1(): void {
    this.displaymod1=false;
    this.authData =  { email: '', password: ''}
    this.loginMsg = undefined
    console.log(this.loginMsg)
  }
  show2(): void{
    this.displaymod2=true
  }
  hide2(): void {
    this.displaymod2=false
  }
  alternate(): void{
    this.displaymod=!this.displaymod
    this.displaymod1=!this.displaymod1
  }
  toHome(): void{
    this.hamburger=false
    this.router.navigate(['/home' , {section: "principal"}])
  }
  toManaging(): void{
    this.hamburger=false
    this.router.navigate(['/home' , {section: "board"}])

  }
  toEducation(): void{
    // document.getElementById("education program").scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start',
    //   inline: 'nearest'
    // });
    this.hamburger=false
    this.router.navigate(['/units'])
  }
  display(id: number): void{
    console.log(id)
    if(!this.connected) {
      this.displaymod1=true
    }
    else{
      this.router.navigate(['/unit' , {unitid: id}])
    }
  }
  openH(): void {
    this.hamburger= !this.hamburger
    console.log(this.hamburger)
  }
  authUser(): void {
    console.log(this.authData)
    this.userService.authUser(this.authData).subscribe((resp) => {
      console.log(resp.msg)
      if(resp.msg!== undefined){
        if(resp.msg==="account not active"){
          this.loginMsg="not active"
        }
        if(resp.msg==="wrong password or email"){
          this.loginMsg="false"
        }

      }else{
        this.loginMsg="good"
        this.connected=true
        // console.log(this.loginMsg)
        this.user=resp
        if(this.user.role==="admin"){
          this.isAdmin=true
        }else{
          this.isAdmin=false
        }
        // console.log(this.user)
        this.hide1()
      }
    })
  }
  getCurrent(): void {
    this.userService.getCurrent().subscribe((resp) => {
      console.log(resp)
      if(resp.id!== undefined){
        this.user=resp

        this.connected=true
        if(this.user.role==="admin"){
          this.router.navigate(['/home' , {section: "principal"}])
        }
      }
      if(resp==="not logged in"){
        this.router.navigate(['/home' , {section: "principal"}])
      }
      
    })
  }
  logout(): void {
    this.userService.logout().subscribe((resp) => {
      console.log(resp)
      if(resp==="session reseted"){
        this.connected=false
        this.isAdmin=false
        this.hamburger=false
        this.router.navigate(['/home' , {section: "principal"}])
        

      }
    })
  }
  addNewUser(): void {
    this.userService.addUser(this.userData).subscribe((resp) => {
      console.log(resp)
      if(resp.msg!== undefined){
        if(resp.msg==="please fill all required information"){
          this.signinMsg="missing"
        }
        if(resp.msg==="user with that email exists already"){
          this.signinMsg="exist"
        }

      }else{
        this.signinMsg="good"
        console.log("next")
      }
    })
  }
  qcm(): void {
    this.router.navigate(['/qcm' , {lessonId: this.id}])
  }
}
