import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { CoursesComponent } from "./courses.component";

@NgModule({
  declarations: [CoursesComponent],
  imports: [BrowserModule, CommonModule],
  exports: [CoursesComponent],
  providers: [],
})
export class CoursesModule {}
