<!DOCTYPE html>
<html dir="rtl" lang="ar">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <style>
        html {
            /*overflow: auto;*/
            position:fixed;
            padding:0;
            margin:0;

            top:0;
            left:0;

            width: 100%;
            height: 100%;
            font-size: 16px;
            line-height: 21px;
            overflow-y: auto;
        }
        nav{
            display: inline;
            width: 100%;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        header{
            padding:0;
            margin:0;
            background-color: white;
            border-bottom: 1px solid #d6d6d5;
            width: 100%;
            z-index: 1000;
            max-height: 7.5vh;
            position: fixed;
            min-height: 60px;
            
        }
        .heads{
            width: 95%;
            margin: auto;
        }
        .head{
           
           float: left;
           padding-bottom: 0;
           margin-bottom: 0;
           

       }
       .head li{
            float: left;
            display: inline;   
            height: 60px;
            line-height: 60px;
            padding-bottom: 0;
        }
        .head li a {
            text-decoration: none;
            color:  #3f3a64;
            font-weight: 500;
            white-space: nowrap;
            transition: 0.2s color ease;
            cursor: pointer;
            padding-bottom: 0;
        }
        .head li a:hover{
            color:  #20ad96;
        }
        .gras{
            font-weight: 700 !important;
        }
        .active{
            color: crimson !important;
            text-decoration: underline !important;
        }
        .name{
            display: inline;
            color:  #20ad96;
            font-weight: 700;
            height: 60px;
            line-height: 60px;
            font-size: 18px;
            margin-right: 10px;
        }
        .home{
            width: 95%;
            margin: auto;
            min-height: 100vh;
            margin-top: 60px;
        }
        .wrapper{
            width: 95%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        
        }
        .grid{
            height: 90%;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 10px;
            /* margin-top: 12%; */
            justify-content: center;
            align-items: center;
            text-align: center;

        }
        .one{
            grid-column: 1;
            display: flex;
            min-height: 500px;
            
            justify-content: center;
            align-items: center;
            
        }
        .subone{
            width: 80%;
            text-align: start;
            height: 100%;
            
        }
        .subone h1{
            font-size: 4rem;
            line-height: 4.5rem;
        }
        .subone p{
            font-size: 1.5rem !important;
            line-height: 2rem !important;
        }
        .subone button{
            font-size: 1.5rem !important;
            line-height: 2rem !important;
        }
        .two{
            grid-column: 2;
        }
        .modal {
            display: none; /* Hidden by default */
            position: fixed; /* Stay in place */
            z-index: 10000; /* Sit on top */
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: hidden; /* Enable scroll if needed */
            background-color: rgb(0,0,0); /* Fallback color */
            background-color: rgba(0, 0, 0, 0.83); /* Black w/ opacity */
        
        }
        .modal-content {
            background-color: #fefefe;
            margin: 4.5% auto; /* 15% from the top and centered */
            padding:0 20px ;
            border: 1px solid #888;
            width: 35%;
            height: 85%; /* Could be more or less, depending on screen size */
            overflow-y: auto;
            border-radius: 10px;
            
        }
        .modal-content1 {
            background-color: #fefefe;
            margin: 2% auto; /* 15% from the top and centered */
            padding:0 20px ;
            border: 1px solid #888;
            width: 60%;
            height: 95%; /* Could be more or less, depending on screen size */
            overflow-y: auto;
            border-radius: 10px;
            
        }
        .img{
            width: 250px;
            height: 350px;
        }
        .desc{
            text-align: start;
        }
        .h2{
            font-weight: 200;
        }
        
        .open{
            display: block;
            
        }
        .hide{
            display: none;
        }
        .close {
    
            color: black;
            float: right;
            font-size: 28px;
            font-weight: bold;
            margin-left: 99%;
            margin-right: -1%;
            top: 3%;
        }
        .close:hover,
        .close:focus {
            color: rgb(122, 4, 4);
            text-decoration: none;
            cursor: pointer;
        }
        .container{
            width: 85%;
            text-align: center;
        }
        .modal-content form{
            text-align: start;
        }
        .modal-content input{
            height: 50px;
        }
        .modal-content label{
            font-size: 1.2rem;
            font-weight: 500;
        }
        .modal-content button{
            font-size: 1.5rem;
            font-weight: 700;
        }
        .modal-content a{
            color: #0d6efd;
            text-decoration: underline;
        }
        .Managing{
            min-height: 100vh;
            display: flex; 
            
            justify-content: center;
        }
        .education{
            height: 100vh;
            display: flex; 
            align-items: center;
            justify-content: center;
        }
        .content{
            width: 100%;
            margin-top: 3vh;
           
        }
        .content h1{
            /* font-size: 1.8rem; */
            text-align: center;
            margin: auto;
        }
        .content1{
            width: 100%;
            margin-top: 9vh;
           
        }
        .content1 h1{
            /* font-size: 1.8rem; */
            text-align: center;
            margin: auto;
        }
        .unit h1{
            margin-top: 3px;
            margin-bottom: 4px;
            font-size: 2rem;
        }
        .unit p{
            text-align: center;
            font-size: 1.1rem;
        }
        .units{
            height: 75vh;
            width: 95%;
            margin: auto;
            margin-top: 3vh;
            margin-bottom: 10px;
        }
        .unit{
            height: auto;
            /* max-height: 10vh; */
            /* background-color: crimson; */
            width: 100%;
            border: 1px solid gray;
            border-bottom-right-radius:  20px;
            border-top-left-radius: 20px;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;
            margin-bottom: 1vh;
        }
        .unit:hover {
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
            background-color:  #20ad96ab;
            cursor: pointer;
        }
        .cards{
            height: auto;
            width: 85%;
            margin: auto;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            align-items: center;
            overflow-y: auto;
        }
        .card {
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;
            border-radius: 5px;
            /* margin-left: 60px; */
            width: 262px;
            margin-bottom: 10px;
            height: 400px;
        }
        .card img{
            border-radius: 5px 5px 0 0;
        }
        .card:hover {
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        }
        .container1 {
            padding: 2px 16px;
            border-top: 1px solid rgba(128, 128, 128, 0.342);
            width: 100%;
        }
        .hamburger {
            display: none;
            position: absolute;
            top: 15px;
            left: 2.5%;
        }
        .bar {
            display: block;
            width: 25px;
            height: 3px;
            margin: 5px auto;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            background-color:  #20ad96ab;
        }
        .error{
            color: crimson;
            font-weight: 500;
        }
        .er{
            font-size: 1.2rem;
        }
        @media only screen and (max-width:430px){
            .name{
                font-size: 1rem !important;
                margin-right: 3px;
            }
        }
        @media only screen and (max-width:1281px){
            .head{
                position: fixed;
                right: -100%;
                display: flex;
                flex-direction: column-reverse;
                background-color: #fff;
                width: 100%;
                border-radius: 10px;
                text-align: center;
                transition: 0.3s;
                box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
                align-items: center;
                text-align: center;
            }
            .head li{
                margin-left: 0rem !important;
                text-align: center;

            }
            .head li button{
                margin: auto;
            }
            .head ul{
                padding-inline-start: 0px;
            }
            .head.active1{
                right: 0;
            }
            .hamburger {
                display: block;
                cursor: pointer;
            }
            .hamburger.active1 .bar:nth-child(2) {
                opacity: 0;
            }
            .hamburger.active1 .bar:nth-child(1) {
                transform: translateY(8px) rotate(45deg);
            }

            .hamburger.active1 .bar:nth-child(3) {
                transform: translateY(-8px) rotate(-45deg);
            }

        }
         @media only screen and (max-width:768px){
            .grid{
                
                grid-template-rows: repeat(2, 1fr);
            }
            .one{
                grid-column: 1/3;
                grid-row: 2;
                column-gap: 0;

            }
            .subone{
                text-align: center;
            }
            .two{
                margin-top: 60px;
                grid-column: 1/3;
                grid-row: 1;
                column-gap: 0px;
                text-align: center;


            }
            .two img{
                width: 100%;
            }
            .modal-content{
                width: 98%;
                height: auto;
            }
            .modal-content1{
                width: 98%;
            }
        } 
        .par{
            font-size: 1.2rem;
        }
        .list{
            text-align: start;
        }
        .list li{
            margin-bottom: 10px;
        }
        .btn-toolbar button{
            font-size: 1.1rem !important;
        }
    </style>
</head>
<body>
    <header>
        <div class="nav">
            <nav class="heads">
                <img src="../../assets/p1-r1.png" alt="ssss" height="50px" >
                <p class="name">مركزالطب الإسلامي للدراسات العلاجية و التطبيقية</p>
                <ul class="head" [ngClass]="hamburger  ? 'active1': ''">
                    <li style="margin-left: 0;" *ngIf="!this.connected"><button class="btn btn-primary btn-hover-secondary gras" (click)="this.show()" (click)="$event.stopPropagation()">إشترك معنا</button></li>
                    <li style="margin-left: 0;" *ngIf="this.connected"><button class="btn btn-primary btn-hover-secondary gras" (click)="this.logout()" (click)="$event.stopPropagation()">تسجيل خروج</button></li>
                    <li style="margin-left: 2rem;" *ngIf="!this.connected"><a class="gras" (click)="this.show1()" (click)="$event.stopPropagation()">تسجيل الدخول</a></li>
                    <li style="margin-left: 2rem;" *ngIf="this.connected"><a class="gras" (click)="this.show1()" (click)="$event.stopPropagation()"> مرحبا، {{ user.name }}</a></li>
                    <li style="margin-left: 2rem;"><a class="gras" >إتصل بنا</a></li>
                    <li style="margin-left: 2rem;"><a class="gras" >الأحداث</a></li>
                    <li style="margin-left: 2rem;"><a class="gras" >الرزنامة</a></li>
                    <li style="margin-left: 2rem;" *ngIf="!isAdmin"><a class="gras" (click)="this.toEducation()">برنامج التعليم</a></li>
                    <li style="margin-left: 2rem;" *ngIf="isAdmin"><a class="gras" (click)="this.AM()">إدارة المستخدمين</a></li>
                    <li style="margin-left: 2rem;"><a class="gras" (click)="this.toManaging()">الهيئة المديرة</a></li>
                    <li style="margin-left: 2rem;"><a class="gras" (click)="this.toHome()" >الرئيسية</a></li>
                </ul>
                <div class="hamburger" (click)="openH()" [ngClass]="hamburger  ? 'active1': ''">
                    <span class="bar"></span>
                    <span class="bar"></span>
                    <span class="bar"></span>
                </div>
            </nav>
            
        </div>
    </header>
    <div class="Managing" id="Managing Body">
        <div class="content1">
            <table class="table table-striped table-hover " style="width:98%; margin:auto; margin-top: 30px;">
                <thead style="background: white; color:  #20ad96; border-bottom: solid 1px #d6d6d5; text-align: center;border-top: solid 1px #d6d6d5;">
                    <tr>
                        <th>#</th>
                        <th>الإسم الكامل</th>
                        <th>البريد الإلكتروني</th>
                        <th>الهاتف</th>
                        <th>دور الحساب</th>
                        <th>حالة الحساب</th>
                        <th>الخيارات</th>
                    </tr>
                </thead>
                <tbody style="border-top: transparent; text-align: center;">
                    <tr *ngFor="let u of users; let i=index">
                        <th>{{ i+1 }}</th>
                        <td>{{ u.name }}</td>
                        <td>{{ u.email }}</td>
                        <td>{{ u.phone }}</td>
                        <td>{{ u.role }}</td>
                        <td *ngIf="u.state==='enabled'">مفعل</td>
                        <td *ngIf="u.state==='disabled'">غير مفعل</td>
                        <td class="btn-toolbar" style="text-align: center; justify-content: center;">
                            <button type="button" class="btn btn-primary btn-hover-secondary " style="margin-left: 10px; width: 100px;" [disabled]="u.role==='admin'" *ngIf="u.state==='disabled'" (click)="updateState('enabled', u.id)">تفعيل</button>
                            <button type="button" class="btn btn-secondary " style="margin-left: 10px; width: 100px;" [disabled]="u.role==='admin'" *ngIf="u.state==='enabled'" (click)="updateState('disabled', u.id)">إبطال التفعيل</button>
                            <button type="button" class="btn btn-danger " (click)="deleteUser(u.id)"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"  style="margin-bottom: 2px;" >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                              </svg>
                            </button>
                        </td>
        
                    </tr>
                </tbody>
            </table>
        </div>
        
    </div>

    
    
   
   
    
    <div id="myModal" class="modal" [ngClass]="displaymod  ? 'open': 'hide'"  >

        <!-- Modal content -->
        <div id="ss" class="modal-content" (click)="$event.stopPropagation()">
            <span class="close" (click)="hide()" >&times;</span><br>
            <div class="container" *ngIf="this.signinMsg!=='good'">
                <h1>إشترك الأن</h1><br><br>
                <form>
                    <div class="form-group">
                        <label for="completeName" class="mb-2" style="margin-left: 5px;">الاسم الكامل</label>
                        <input [(ngModel)]="userData.name" name="completeName" id="completeName" type="text" class="form-control" placeholder="أدخل الاسم الأول والأخير">
                    </div><br>
                    <div class="form-group">
                        <label for="email" class="mb-2" style="margin-left: 5px;">البريد الإلكتروني</label>
                        <input [(ngModel)]="userData.email"  name="email" id="email" type="email" class="form-control" placeholder="nom@email.com">
                    </div>
                    <br>
                    <div class="form-group">
                        <label for="phone" class="mb-2" style="margin-left: 5px;">الهاتف</label>
                        <input [(ngModel)]="userData.phone"  name="phone" id="phone" type="number" class="form-control" placeholder="ادخل رقم الهاتف">
                    </div>
                    <br>
                    <div class="form-group">
                        <label for="password" class="mb-2" style="margin-left: 5px;">كلمة السر</label>
                        <input [(ngModel)]="userData.password"  name="password" id="password" type="password" class="form-control" placeholder="ادخل كلمة السر">
                    </div><br><br>
                    <button class="btn btn-success" style="width: 100%;" (click)="addNewUser()">إنشاء حساب</button>
                </form>
                <br>
                <p class="error er" *ngIf="this.signinMsg==='missing'">يرجى ملء جميع المعلومات المطلوبة</p>
                <p class="error er" *ngIf="this.signinMsg==='exist'">مستخدم هذا البريد الإلكتروني موجود بالفعل</p>
                <br>
                <p>لديك حساب؟<a (click)="this.alternate()" (click)="$event.stopPropagation()" style="margin-right: 5px;">سجل الدخول</a></p>
            </div>
            <div class="container" *ngIf="this.signinMsg==='good'">
                <h1>إتمام الإشتراك</h1><br><br>
                <p class="par">لإتمام الإشتراك و لتفعيل حسابك من قبل الهيئة المديرة، يرجى منك إرسال المستندات التالية:</p>
                <ul class="par list">
                    <li>صورة من بطاقة التعريف الوطنية</li>
                    <li>صورة هوية</li>
                    <li>صورة من الشهادة في المستوى العلمي</li>
                    <li> وصل شراء كتاب التغذية و علاج أمراضها من دار المالكية للطباعة و النشر و التوزيع أو الاتصال بالادارة عن طريق البريد الالكتروني لشراءه</li>
                </ul>
                <p class="par">البريد الإلكتروني التالي:</p>
                <h2 style="color:  #20ad96;">islammedicale@gmail.com</h2>
            </div>
           
        </div>
    </div>
    <div id="myModal" class="modal" [ngClass]="displaymod1  ? 'open': 'hide'"  >

        <!-- Modal content -->
        <div id="ss" class="modal-content" (click)="$event.stopPropagation()">
            <span class="close" (click)="hide1()" >&times;</span><br>
            <div class="container">
                <h1>مرحبا بعودتك</h1><br><br>
                <form>
                    <div class="form-group">
                        <label for="email" class="mb-2" style="margin-left: 5px;">البريد الإلكتروني</label>
                        <input [(ngModel)]="authData.email"  name="email" id="email" type="text" class="form-control" placeholder="nom@email.com">
                    </div>
                    <br>
                    <div class="form-group">
                        <label for="password" class="mb-2" style="margin-left: 5px;">كلمة السر</label>
                        <input [(ngModel)]="authData.password" name="password" id="password" type="password" class="form-control" placeholder="ادخل كلمة السر">
                    </div>
                    <a href="">نسيت كلمة السر؟</a><br><br>
                    <button class="btn btn-success" style="width: 100%;" (click)="authUser()" >سجل الدخول</button>
                </form>
                <br>
                <p class="error" *ngIf="this.loginMsg==='not active'">هذا الحساب غير مفعل <br> يرجى الإتصال هذا البريد الإلكتروني لمزيد من المعلومات</p>
                <p *ngIf="this.loginMsg==='not active'">islammedicale@gmail.com</p>
                <p class="error er" *ngIf="this.loginMsg==='false'">كلمة سر أو بريد إلكتروني خاطئ</p>
                <br>
                <p>ليس لديك حساب؟<a (click)="this.alternate()" (click)="$event.stopPropagation()"style="margin-right: 5px;">أنشأ حساب</a></p>
            </div>
           
        </div>
    </div>
    

    
</body>
</html>