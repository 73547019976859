import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../globals';
import { User, UserService } from '../user.service';

@Component({
  selector: 'app-qcm',
  templateUrl: './qcm.component.html',
  styleUrls: ['./qcm.component.css']
})
export class QcmComponent implements OnInit {
  globals: Globals;
  id: any
  lessonpart: any
  try: any
  @Input() authData = { email: '', password: ''}
  @Input() userData = { name: '', email: '', password: ''}
  @Input() evalData = { unit: 0, lesson: 0, lessonpart: 0, try: 0}
  qcm111: boolean[] = [true,false,true,false,true, true,true, false, true, false, true, true, true, false, true,false, true, false, true, false,true, false,true, true, true,false]
  qcma111: boolean[] = [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]
  qcma112: boolean[] = [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]
  qcma113: boolean[] = [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]
  qcm112: boolean[] = [true,false,true,false,true, true,true, false, true, false, true, true, true, false, true,true, true, true, true, false,true, false,true, true, true,false]
  qcm113: boolean[] = [true,false,true,false,true, true,true, false, true, false, true, true, true, false, true,false, true, false, true, true,true, true,true, true, true,false,true,false,true,true,true,false]
  displaymod: boolean;
  displaymod1: boolean;
  displaymod2: boolean
  displayy: boolean;
  connected: boolean
  section: string
  hamburger: boolean=false
  loginMsg: string
  signinMsg:string
  isAdmin: boolean
  user: User
 

  constructor(private route: ActivatedRoute, private router: Router, globals: Globals, public userService: UserService) {
    this.globals = globals;
   }
   @HostListener('document:click', ['$event'])
   clickout() {
     if (this.displaymod) {
       this.hide()
     }
     if (this.displaymod1) {
       this.hide1()
     }
     if (this.displaymod2) {
       this.hide2()
     }
   }

  ngOnInit(): void {
    this.id=this.route.snapshot.paramMap.get('lessonId')
    this.getCurrent()
  }
  show(): void {
    this.displaymod=true
    this.hamburger=false

  }
  hide(): void {
    this.displaymod=false;
    this.userData = { name: '', email: '', password: ''}
    this.signinMsg = undefined
  }
  show1(): void {
    this.displaymod1=true
    this.hamburger=false

  }
  hide1(): void {
    this.displaymod1=false;
    this.authData =  { email: '', password: ''}
    this.loginMsg = undefined
    console.log(this.loginMsg)
  }
  show2(): void{
    this.displaymod2=true
  }
  hide2(): void {
    this.displaymod2=false
  }
  alternate(): void{
    this.displaymod=!this.displaymod
    this.displaymod1=!this.displaymod1
  }
  toHome(): void{
    this.hamburger=false
    this.router.navigate(['/home' , {section: "principal"}])
  }
  toManaging(): void{
    this.hamburger=false
    this.router.navigate(['/home' , {section: "board"}])

  }
  toEducation(): void{
    // document.getElementById("education program").scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start',
    //   inline: 'nearest'
    // });
    this.hamburger=false
    this.router.navigate(['/units'])
  }
  display(id: number): void{
    console.log(id)
    if(!this.connected) {
      this.displaymod1=true
    }
    else{
      this.router.navigate(['/unit' , {unitid: id}])
    }
  }
  openH(): void {
    this.hamburger= !this.hamburger
    console.log(this.hamburger)
  }
  authUser(): void {
    console.log(this.authData)
    this.userService.authUser(this.authData).subscribe((resp) => {
      console.log(resp.msg)
      if(resp.msg!== undefined){
        if(resp.msg==="account not active"){
          this.loginMsg="not active"
        }
        if(resp.msg==="wrong password or email"){
          this.loginMsg="false"
        }

      }else{
        this.loginMsg="good"
        this.connected=true
        // console.log(this.loginMsg)
        this.user=resp
        if(this.user.role==="admin"){
          this.isAdmin=true
        }else{
          this.isAdmin=false
        }
        // console.log(this.user)
        this.hide1()
      }
    })
  }
  getCurrent(): void {
    this.userService.getCurrent().subscribe((resp) => {
      console.log(resp)
      if(resp.id!== undefined){
        this.user=resp
        this.lessonpart = this.user.lessonpart+1
        this.try = this.user.try+1
        console.log(this.lessonpart)
        console.log(this.try)
        this.connected=true
        if(this.user.role==="admin"){
          this.router.navigate(['/home' , {section: "principal"}])
        }
      }
      if(resp==="not logged in"){
        this.router.navigate(['/home' , {section: "principal"}])
      }
      
    })
  }
  logout(): void {
    this.userService.logout().subscribe((resp) => {
      console.log(resp)
      if(resp==="session reseted"){
        this.connected=false
        this.isAdmin=false
        this.hamburger=false
        this.router.navigate(['/home' , {section: "principal"}])
        

      }
    })
  }
  addNewUser(): void {
    this.userService.addUser(this.userData).subscribe((resp) => {
      console.log(resp)
      if(resp.msg!== undefined){
        if(resp.msg==="please fill all required information"){
          this.signinMsg="missing"
        }
        if(resp.msg==="user with that email exists already"){
          this.signinMsg="exist"
        }

      }else{
        this.signinMsg="good"
        console.log("next")
      }
    })
  }
  qcma(): void {
    let score=0;
    let total=0;
    let count=0;
    if(this.id==1 && this.lessonpart==1 && this.try==1){
      total= this.qcm111.length
      console.log(this.qcma111.length)
      console.log(this.qcm111.length)
  
      for(let i=0; i< this.qcma111.length;i++){
        if(this.qcm111[i]===this.qcma111[i]){
          count++;
        }
      }
    }
    if(this.id==1 && this.lessonpart==1 && this.try==2){
      total= this.qcm112.length
      console.log(this.qcma112.length)
      console.log(this.qcm112.length)
  
      for(let i=0; i< this.qcma112.length;i++){
        if(this.qcm112[i]===this.qcma112[i]){
          count++;
        }
      }
    }
    if(this.id==1 && this.lessonpart==1 && this.try==3){
      total= this.qcm113.length
      console.log(this.qcma113.length)
      console.log(this.qcm113.length)
  
      for(let i=0; i< this.qcma113.length;i++){
        if(this.qcm113[i]===this.qcma113[i]){
          count++;
        }
      }
    }
    
    score = count/total * 100
    console.log(count)
    console.log(score)
    if(score<75 && this.user.try<3){
      this.evalData.unit=this.user.unit
      this.evalData.lesson=this.user.lesson
      this.evalData.lessonpart=this.user.lessonpart
      this.evalData.try=this.user.try+1
      this.userEval(this.evalData, this.user.id)
      window.alert("لقد تحصلت على " + Math.trunc(score) +"% " + "في الإختبار، لكن للأسف هذا لا يمكنك من تجاوز الإختبار. الرجاء المحاولة مرة أخرى ")
      this.router.navigate(['/unit' , {unitid: this.user.unit+1}])
    }
    if(score>=75){
      if(this.id==1 && this.lessonpart==1){
        this.evalData.unit=this.user.unit
        this.evalData.lesson=this.user.lesson
        this.evalData.lessonpart=this.user.lessonpart+1
        this.evalData.try=0
        this.userEval(this.evalData, this.user.id)
        window.alert("لقد تجاوزت الإختبار بنجاح")
        this.router.navigate(['/lesson' , {lessonId: 1}])
      }
      if(this.id==1 && this.lessonpart==2){
        this.evalData.unit=this.user.unit
        this.evalData.lesson=this.user.lesson+1
        this.evalData.lessonpart=0
        this.evalData.try=0
        this.userEval(this.evalData, this.user.id)
        this.router.navigate(['/unit' , {unitid: this.user.unit+1}])
      }
    }
  }
  userEval(evalu: any, id: number): void {
    console.log(id)
    this.userService.updateEvaluation(evalu, id).subscribe((resp) =>{
      console.log(resp)
      this.getCurrent()
    })
  }

}
